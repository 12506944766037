<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand brand-logo" to="/">
			<!-- <b-img
				v-if="!($store.state.auth.configData !== '' && $store.state.auth.configData.name !== 'BeOnTrips' && !imageError)"
				:src="$store.state.auth.configData.clubLogo"
				alt="logo"
				width="180"
				@error="noImage"
			/>
			<Logo v-else/> -->
			<Logo/>
          <!-- <b-img :src="appLogoImage" alt="logo" /> -->
          <h2 class="brand-text mb-0" />
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import { name } from "@/helpers/constant";
import Logo from "@/layouts/frontend-logo/Logo.vue";

export default {
	components: {
		BLink,
		Logo,
		BImg
	},
	data() {
		return {
			imageError: false
		}
	},
	methods: {
		noImage() {
			this.imageError = true
		}
	},
	setup() {
		// App Name
		const { appLogoImage } = $themeConfig.app;
		return {
			appLogoImage,
			name
		};
	}
};
</script>

<style lang="scss">
.brand-logo {
	svg {
		height: 40px;
		width: 90px;
	}
	&:hover {
		svg {
			* {
				fill: #20efe9;
			}
		}
	}
}
</style>
